<template>
  <div class="home">
    <div class="header">
      <div class="logo">
        <img src="../assets/u1.png" />
        疫木除治全流程追溯平台
        <!-- 松材线虫病疫情大数据分析系统V1.0 -->
      </div>
      <div class="menu">
        <div
          class="eachMenu"
          v-for="(menu, index) in list"
          :key="index"
          @click="selectCurMenu(menu, index)"
          @mouseover="openDownMenu(menu, index)"
          @mouseout="closeDownMenu"
        >
          <!-- 超级管理员  -->
          <div class="menuList" v-if="groupIdList[0] == '1'">
            <div class="text" :class="[xuan == menu.path ? 'index' : '']">
              {{ menu.title }}
            </div>
          </div>
		  <!-- 普通用户不可见系统管理模块 -->
		     <div class="menuList" v-if="groupIdList[0] !== '1'">
            <div class="text" v-if="menu.key !== '2'" :class="[xuan == menu.path ? 'index' : '']">
              {{ menu.title }}
            </div>
          </div>
          <div
            class="downMenu"
            v-if="menu.children && groupIdList[0] == '1'"
            :style="curHoverIndex == index ? CurHeight : ''"
          >
            <div
              v-for="(downMenu, indexDown) in menu.children"
              class="eachDownMenu"
              :class="[$route.path == downMenu.path ? 'active' : '']"
              :key="indexDown"
              @click="chooseCurDownMenu(downMenu, index)"
            >
              <span style="margin-left: 5px">{{
                downMenu.title
              }}</span>
            </div>
          </div>
          <div
            class="downMenu"
            v-if="menu.children && groupIdList[0] !== '1'"
            :style="curHoverIndex == index ? CurHeight : ''"
          >
            <div
              v-if="menu.key !== '2'"
              v-for="(downMenu, indexDown) in menu.children"
              class="eachDownMenu"
              :class="[$route.path == downMenu.path ? 'active' : '']"
              :key="indexDown"
              @click="chooseCurDownMenu(downMenu, index)"
            >
              <span style="margin-left: 5px">{{
                downMenu.title
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="logOut">
        <!-- 当前定位：江苏省镇江市 -->
        <a-popconfirm
          style="display: flex; align-items: center"
          title="是否退出登录"
          placement="bottom"
          ok-text="是"
          cancel-text="否"
          @confirm="confirm"
        >
          <div class="admin">
            <img src="../assets/u1.png" alt="" />
          </div>
          {{ username }}
        </a-popconfirm>
      </div>
    </div>

    <div class="center">
      <router-view></router-view>
    </div>
    <!-- <div class="footer">@济南祥辰科技有限公司</div> -->
  </div>
</template>

<script>
import { Menu } from "ant-design-vue";

const SubMenu = {
  template: `
    <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
    <span slot="title">
        <span>{{ menuInfo.title }}</span>
        </span>
    <template v-for="item in menuInfo.children">
      <a-menu-item v-if="!item.children" :key="item.path">

        <span>{{ item.title }}</span>
      </a-menu-item>
      <sub-menu v-else :key="item.path" :menu-info="item"/>
    </template>
    </a-sub-menu>
  `,
  name: "SubMenu",
  // must add isSubMenu: true 此项必须被定义
  isSubMenu: true,
  props: {
    // 解构a-sub-menu的属性
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
export default {
  name: "LayOutIndex",
  components: {
    "sub-menu": SubMenu,
  },
  data() {
    return {
      groupIdList: sessionStorage.getItem("groupId"),
      username: "",
      // openKeys: ['index'],
      // rootSubmenuKeys: ['/user'],
      hyModifyEli: sessionStorage.getItem("hyModifyEli"),
      list: [
        {
          key: "1",
          title: "首页",
          path: "/index",
        },
        {
          key: "5",
          title: "普查动态",
          path: "/examine",
          children: [
            {
              key: "5.1",
              title: "普查详情",
              path: "/examine",
            },
            {
              key: "5.2",
              title: "枯死木分布",
              path: "/state",
            },
            // ,{
            //   key: '5.3',
            //   title: '二维码管理',
            //   path: '/qrcode'
            // }
          ]
				},
				{
					key: '3',
					title: '综合除治',
					path: '/list',
          children: [
            {
              key: '3.1',
              title: '除治详情',
              path: '/list'
            },{
              key: '3.2',
              title: '打孔注药详情',
              path: '/punching'
            }
          ]
				},
				{
					key: '4',
					title: '二维码管理',
					path: '/admin',
				},
        // {
				// 	key: '6',
				// 	title: '任务管理',
				// 	path: '/taskManagement',
				// },
				{
					key: '2',
					title: '系统设置',
					path: '/manager',
					children: [
						{
						  key: '2.1',
						  title: '用户管理',
						  path: '/manager/use'
						},
						{
							key: '2.2',
							title: '角色管理',
							path: '/manager/role',
						},
						{
							key: '2.3',
							title: '菜单管理',
							path: '/manager/menu',
						},
						// {
						//   key: '2.4',
						//   title: '二维码管理',
						//   path: '/manager/ewm',
						// },
						{
							key: '2.5',
							title: '项目管理',
							path: '/manager/project',
						},
						{
							key: '2.6',
							title: '工作组管理',
							path: '/manager/worker',
						},
					],
				},
			],
			curHoverIndex: -1,
			CurHeight: '',
			xuan: '',
		}
	},
  created() {
    const a = this.$route.path.split("/");
    this.xuan = "/" + a[1];
  },
  mounted() {
    this.username = sessionStorage.getItem("username");
    if(this.hyModifyEli === '1') {
      this.list[2].children.push(
        {
          key: '3.3',
          title: '除治修改',
          path: '/listAmend'
        }
      )
    }
  },
  methods: {
    chooseCurDownMenu(downMenu, index) {
      this.$router.push(downMenu.path);
      const a = downMenu.path.split("/");
      this.xuan = "/" + a[1];
    },
    closeDownMenu() {
      this.curHoverIndex = -1;
    },
    selectCurMenu(menu, index) {
      // console.log(this.xuan)
      if (!menu.children) {
        this.$router.push(menu.path);
        const a = menu.path.split("/");
        this.xuan = "/" + a[1];
      }
    },
    openDownMenu(item, index) {
      this.curHoverIndex = index;
      if (item.children) {
        let num = 36 * item.children.length;
        this.CurHeight = "height:" + num + "px";
      }
      // console.log(this.CurHeight)
    },
    confirm() {
      this.$router.push({
        path: "/",
      });
      window.localStorage.clear();
      // 退出后禁止返回上一页
      history.pushState(null, null, document.URL);
      window.addEventListener(
        "popstate",
        function (e) {
          history.pushState(null, null, document.URL);
        },
        false
      );
    },
  },
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100vh;
  background: #eae2e2;
  position: relative;
  color: #ffffff;
}

.header {
  width: 100%;
  height: 50px;
  display: flex;
  background: url(../assets/bg.png) no-repeat;
}

.center {
  width: 100%;
  height: calc(100vh - 50px);
}

.footer {
  width: 100%;
  height: 20px;
  border: 1px saddlebrown solid;
  position: absolute;
  bottom: 0;
}

.logo {
  width: 400px;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  /* background: #2c3e50; */
}

.logo img {
  height: 40px;
  width: auto;
  margin-left: 20px;
  margin-right: 10px;
}

.logOut {
  width: 350px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: flex-start;
  /* background: #fff; */
}

.logOut .admin img {
  color: #fff;
  width: 40px;
  height: 40px;
  /* background-color: #ecedef; */
  border-radius: 50%;
  margin-top: 5px;
  line-height: 40px;
  margin-left: 20px;
  margin-right: 8px;
  font-size: 12px;
}

.ant-menu {
  background-color: transparent;
  color: #ffffff;
  /* #00ccff */
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #00ccff;
  border-color: #00ccff;
}

.ant-menu-horizontal {
  border: none;
}

.menu {
  height: 100%;
  width: calc(100% - 750px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.eachMenu {
  position: relative;
  width: 10rem;
}

.index {
  color: #00ccff;
  border-bottom: 3px solid #00ccff !important;
}

.text {
  /* padding: 0 10px; */
  height: 100%;
  line-height: 50px;
  border-bottom: 3px solid transparent;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.text:hover {
  color: #00ccff;
  border-bottom: 3px solid #00ccff;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.menuList {
  display: flex;
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* padding: 0 30px; */
  height: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.downMenu {
  width: auto;
  height: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 16;
  top: 116%;
  background-color: #003061;
  border-radius: 4px;
  transition: 0.3s;
  overflow: hidden;
  white-space: nowrap;
}

.eachDownMenu {
  padding: 5px 0;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.eachDownMenu:hover {
  color: #00ccff;
  border-bottom: 1px solid #00ccff;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.active {
  color: #00ccff;
  border-bottom: 1px solid #00ccff;
}
</style>
